<!-- Localized -->
<template>
  <div class="px-2 py-4 border bg-blue-100 border-blue-400 rounded-xl flex text-blue-700 items-center form-text">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke-width="1.5"
      stroke="currentColor"
      class="w-6 h-6"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
      />
    </svg>

    <p class="text-sm ml-2"  v-html="getText"></p>
  </div>
</template>

<script>
export default {
  props: {
    fieldData: {
      type: Object,
      require: true,
    },
    error: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    getText() {
      return this.fieldData.label || "";
    },
  },
};
</script>

<style lang="scss" scoped>
@media screen and (max-width:768px) {

  .form-text{
    border-radius: 0px;
  }
  
}
</style>
